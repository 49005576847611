<template>
  <div class="Map">
    <img :src="bgImg">
    <img :src="genBgUrl" v-if="genBgUrl">
    <div class="inX" :style="x" v-if="x"></div>
    <div class="inY" :style="y" v-if="y"></div>
<!--    <div class="block" :style="inpos"  v-if="inpos"></div>-->
    <div class="blocks" :style="inpos" v-if="inpos"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bgImg: '',
      x: '',
      y: '',
      data: [],
      creationSizeX: '',
      creationSizeY: '',
      canvasNumberX: '',
      canvasNumberY: '',
      TolSizeY: '',
      TolSizeX: '',
      inpos: '',
      genBgUrl:''
    }
  },
  methods: {
    clear() {
      this.x = ''
      this.y = ''
      this.inpos=''
    },
    highlights(str) {
      let TolSizeX = this.TolSizeX
      let TolSizeY = this.TolSizeY
      let x = str.x / TolSizeX
      let y = str.y / TolSizeY

      let sumx = Number(x * 100).toFixed(1);
      if (sumx == 0) {
        sumx = 1
      }
      sumx += "%";
      let sumy = Number(y * 100).toFixed(1);
      if (sumy == 0) {
        sumy = 1
      }
      sumy += "%";

      this.x = 'top:' + sumy
      this.y = 'left:' + sumx

      let inpos = `left:${sumx};top:${sumy};display:block;`
      this.inpos = inpos
    },
    init(num, finishList) {

      if (Boolean(num.genStart)==true){
        this.genBgUrl=num.genBgUrl
      }

      this.bgImg = num.bgImg
      this.initdata(num, finishList)
    },
    storagedata() {
      return {
        list: this.data,
        canvasNumberY: this.canvasNumberY
      }
    },
    initdata(num, finishList) {
      //自定义画布尺寸 x
      let xTin = num.creationSizeX
      this.creationSizeX = xTin
      //自定义画布尺寸 y
      let yTin = num.creationSizeY
      this.creationSizeY = yTin
      //行数数量
      let canvasNumberX = num.canvasNumberX
      //列数数量
      let canvasNumberY = num.canvasNumberY
      this.canvasNumberX = canvasNumberX
      this.canvasNumberY = canvasNumberY
      this.TolSizeX = canvasNumberX * xTin
      this.TolSizeY = canvasNumberY * yTin
      //编号
      let titleNumber = num.titleNumber

      let addSum = []
      let number = 0

      for (let j = 0; j < canvasNumberX; j++) {
        let js = j * xTin;
        for (let i = 0; i < canvasNumberY; i++) {
          let ys = i * yTin
          let add = {
            //画布编号
            idNumber: number,
            SidNumber: titleNumber + 'X' + (j + 1) + 'Y' + (i + 1),
            x: js,
            y: ys + 1,
            Sinx: j,
            Siny: i,
            //未认领状态
            start: false,
          }
          number++
          addSum.push(add)
        }
      }

      for (let item in addSum) {
        for (let k in finishList) {
          if (addSum[item].SidNumber == finishList[k].SidNumber) {
            addSum[item].start = true
            break;
          }
        }
      }


      let ColumnsX = []
      for (let i = 0; i < canvasNumberX; i++) {
        let add = ''
        let number = 0;
        for (let item in addSum) {
          if (addSum[item].Sinx == i) {
            if (addSum[item].start == false) {
              number++
            }
          }
        }

        let js = i;

        add = (js + 1) + ' (可用:' + (number) + '个)'
        ColumnsX.push(add)
      }

      this.$parent.initColumnsX(ColumnsX)
      this.data = addSum
    },
    list() {
      return this.data
    }
  }
}
</script>

<style scoped lang="scss">
.Map {
  position: relative;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .inX {
    width: 100%;
    height: 1px;
    background-color: #FFFFFF;
    position: absolute;
    left: 0;
    top: 10px;
  }

  .inY {
    width: 1px;
    height: 100%;
    background-color: #FFFFFF;
    position: absolute;
    left: 10px;
    top: 0
  }

  .block {
    width: 25px;
    height: 25px;
    border: 2px solid #FFFFFF;
    position: absolute;
    left: 0;
    top: 0;
    display: none;
    margin-left: -14.5px;
    margin-top: -14.5px;
  }

  .blocks {
    width: 10px;
    height: 10px;
    background-color: #FFFFFF;
    position: absolute;
    left: 0;
    top: 0;
    display: none;
    margin-left: -4.5px;
    margin-top: -4.5px;
  }
}
</style>
